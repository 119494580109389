import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import { Blog, NewsSettings } from '@content/types/blog';
import { BlogArticleItem } from '@components/blog/BlogArticleItem';
import Loader from '@components/common/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';

const ArticlesList = styled.div`
  .infinite-scroll-component {
    display: flex;
    flex-flow: column;
    position: relative;
    z-index: 10;

    ${respondFrom(
      breakpoints.md,
      css`
        flex-flow: row wrap;
        justify-content: space-between;
      `
    )}
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

interface BlogArticlesListProps {
  articles: Blog[];
  fetchMoreData: Function;
  showLoader: boolean;
  settings: NewsSettings;
  langcode: string;
  urls: Array<string>;
}

export const BlogArticlesList = ({
  articles,
  fetchMoreData,
  showLoader,
  settings,
  langcode,
  urls,
}: BlogArticlesListProps) => {
  return (
    <ArticlesList>
      <InfiniteScroll dataLength={articles.length} next={fetchMoreData} hasMore={true}>
        {articles.map((article, index) => (
          <BlogArticleItem
            key={index}
            article={article}
            settings={settings}
            langcode={langcode}
            urls={urls}
          />
        ))}
      </InfiniteScroll>
      {showLoader && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </ArticlesList>
  );
};
