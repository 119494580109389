import React from 'react';
import styled from '@emotion/styled';
import { colors, mixins, animation, respondFrom, breakpoints, css } from '@styles';
import Collapsible from 'react-collapsible';

import { BlogSearch } from '@components/blog/BlogSearch';
import { BlogCategories } from '@components/blog/BlogCategories';
import { BlogTags } from '@components/blog/BlogTags';
import { BlogInfo } from '@components/blog/BlogInfo';
import { NewsSettings } from '@content/types/blog';

const BlogAccordion = styled.div`
  .Collapsible {
    margin: 20px 0;

    ${respondFrom(
      breakpoints.lg,
      css`
        margin: 0;
        height: auto !important;
        visibility: visible !important;
      `
    )}

    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: ${colors.gray};
      margin-top: 10px;

      ${respondFrom(
        breakpoints.lg,
        css`
          display: none;
        `
      )}
    }

    &__trigger {
      display: block;
      text-transform: uppercase;
      font-weight: 500;
      position: relative;
      z-index: 5;
      ${mixins.transitionDefault};

      ${respondFrom(
        breakpoints.lg,
        css`
          display: none;
        `
      )}

      &::before {
        content: '';
        position: absolute;
        top: -2px;
        right: 5px;
        width: 12px;
        height: 12px;
        border: 2px solid ${colors.green.dark};
        border-left: 0;
        border-top: 0;
        transform: rotate(45deg);
        transform-origin: 6px;
        ${mixins.transitionDefault};
      }

      &.is-open {
        color: transparent;

        &::before {
          transform: translateY(3px) rotate(225deg);
        }

        & + div {
          overflow: visible !important;
        }

        & + div > div {
          transform: translateY(-50px);
        }
      }
    }

    &__contentOuter {
      ${respondFrom(
        breakpoints.lg,
        css`
          height: auto !important;
          overflow: visible !important;
        `
      )}
    }

    &__contentInner {
      transition: ${animation.slow}ms transform linear;

      ${respondFrom(
        breakpoints.lg,
        css`
          transform: none !important;
        `
      )}
    }
  }
`;

interface BlogAsidePanelProps {
  searchInputValue: string;
  onSearchInputChange: Function;
  getAllCategories: Function;
  categoryId: string;
  settings: NewsSettings;
  langcode: string;
  urls: Array<string>;
}

export const BlogAsidePanel = ({
  searchInputValue,
  onSearchInputChange,
  getAllCategories,
  categoryId,
  settings,
  langcode,
  urls,
}: BlogAsidePanelProps) => (
  <BlogAccordion>
    <BlogSearch
      value={searchInputValue}
      onSearchInputChange={onSearchInputChange}
      settings={settings}
    />
    <Collapsible trigger={categoryId ? categoryId : settings.text3}>
      <BlogCategories
        categories={getAllCategories('categories')}
        settings={settings}
        langcode={langcode}
        urls={urls}
      />
      <BlogTags
        tags={getAllCategories('tags')}
        settings={settings}
        langcode={langcode}
        urls={urls}
      />
    </Collapsible>
    <BlogInfo settings={settings} langcode={langcode} urls={urls} />
  </BlogAccordion>
);
