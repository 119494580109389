import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions, mixins } from '@styles';
import { Link } from '@utils';
import { BlogCategoryTitle } from '@components/blog/BlogCategoryTitle';
import * as utils from '@utils';
import { NewsSettings } from '@content/types/blog';

const BlogCategoriesComponent = styled.div`
  margin: 30px 0;
`;

const BlogCategoryList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 10px 0;
  }

  a {
    position: relative;
    text-transform: uppercase;
    color: ${colors.text.default};
    font-size: ${dimensions.fontSize.regular}px;
    font-weight: 300;
    text-decoration: none;

    ${mixins.linkUnderline}

    &.is-active {
      font-weight: 500;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 2px;
        left: -5px;
        width: 2px;
        height: 12px;
        margin: auto;
        background-color: ${colors.green.dark};
      }
    }
  }
`;

interface BlogCategoriesProps {
  categories: Array<string>;
  settings: NewsSettings;
  langcode: string;
  urls: Array<string>;
}

export const BlogCategories = ({ categories, settings, langcode, urls }: BlogCategoriesProps) => (
  <BlogCategoriesComponent>
    <BlogCategoryTitle title={settings.text2} />
    <BlogCategoryList>
      <li>
        <Link to={utils.langLink(langcode, `${urls[15]}/`)} activeClassName="is-active">
          {settings.text3}
        </Link>
      </li>
      {categories.map((category, index) => (
        <li key={index}>
          <Link
            to={utils.langLink(
              langcode,
              `${urls[15]}/${encodeURI(utils.escapeDiacritics(category.toLocaleLowerCase()))}`
            )}
            activeClassName="is-active"
          >
            {category}
          </Link>
        </li>
      ))}
    </BlogCategoryList>
  </BlogCategoriesComponent>
);
