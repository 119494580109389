import React from 'react';
import NewsContainer from '@containers/news';
import useNewsQuery from '@graphql/queries/NewsQuery';
import { PageContext } from '@content/types/pageContext';
import MetaPage from '@components/common/MetaPage';
import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';

interface NewsPageProps extends LocationDependent {
  pageContext: PageContext;
}

export default (props: NewsPageProps) => {
  const newsQuery = useNewsQuery();

  return (
    <>
      <MetaPage
        title={newsQuery.newsSettings[props.pageContext.langcode].seo.title}
        description={newsQuery.newsSettings[props.pageContext.langcode].seo.description}
      />
      <DataLayer location={props.location} />
      <NewsContainer
        {...newsQuery}
        pageContext={props.pageContext}
        urls={props.pageContext.allUrls}
      />
    </>
  );
};
