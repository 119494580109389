import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions, respondFrom, breakpoints, css } from '@styles';
import { Link } from '@utils';
import { NewsSettings } from '@content/types/blog';
import * as utils from '@utils';

const BlogInfoContainer = styled.div`
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      position: static;
    `
  )}
`;

const InfoText = styled.div`
  font-size: ${dimensions.fontSize.small}px;

  h6 {
    font-weight: 700;
    margin: 0 0 15px;
    font-size: ${dimensions.fontSize.regular}px;
  }
`;

const InfoLink = styled(Link)`
  text-transform: uppercase;
  font-size: ${dimensions.fontSize.small}px;
  color: ${colors.green.medium};
`;

interface BlogInfoProps {
  settings: NewsSettings;
  langcode: string;
  urls: Array<string>;
}

export const BlogInfo = ({ settings, langcode, urls }: BlogInfoProps) => (
  <BlogInfoContainer>
    <InfoText>{utils.SafeHtml(settings.tresc)}</InfoText>
    <InfoLink to={utils.langLink(langcode, urls[0])}>{settings.button1}</InfoLink>
  </BlogInfoContainer>
);
