import React from 'react';
import styled from '@emotion/styled';
import Input from '@components/common/Input';

import searchIcon from '@assets/svg/search-icon.svg';
import { NewsSettings } from '@content/types/blog';

const BlogSearchComponent = styled.div`
  position: relative;
`;

const SearchButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  border: none;
  width: 50px;

  &:focus {
    outline: none;
  }

  img {
    padding-top: 5px;
  }
`;

interface BlogSearchProps {
  value: string;
  onSearchInputChange: Function;
  settings: NewsSettings;
}

export const BlogSearch = ({ value, onSearchInputChange, settings }: BlogSearchProps) => (
  <BlogSearchComponent>
    <Input
      typeInput="text"
      idInput="searchBlog"
      labelPosition="vertical"
      value={value}
      action={onSearchInputChange}
      placeholder={settings.text1}
      buttonSpace="45px"
    />
    <SearchButton>
      <img src={searchIcon} alt="" />
    </SearchButton>
  </BlogSearchComponent>
);
