import React from 'react';
import styled from '@emotion/styled';
import { dimensions } from '@styles';

const Title = styled.p`
  font-weight: 700;
  margin: 0 0 15px;
  font-size: ${dimensions.fontSize.regular}px;
`;

interface BlogCategoryTitleProps {
  title: string;
}

export const BlogCategoryTitle = ({ title }: BlogCategoryTitleProps) => <Title>{title}</Title>;
