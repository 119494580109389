import React, { Component } from 'react';
import styled from '@emotion/styled';
import flatten from 'lodash.flatten';

import { respondFrom, breakpoints, css } from '@styles';
import { Blog, NewsSettings } from '@content/types/blog';
import { BlogArticlesList } from '@components/blog/BlogArticlesList';
import { BlogAsidePanel } from '@components/blog/BlogAsidePanel';
import { PageContext } from '@content/types/pageContext';

const BlogGridComponent = styled.div`
  display: flex;
  flex-flow: column;
  padding-top: 60px;
  padding-bottom: 120px;

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-flow: row;
      padding-bottom: 0;
    `
  )}

  > div {
    ${respondFrom(
      breakpoints.lg,
      css`
        &:first-of-type {
          width: 330px;
          padding-right: 100px;
        }
        &:last-of-type {
          flex: 1;
        }
      `
    )}
  }
`;

interface BlogGridProps {
  data: Blog[];
  pageContext: PageContext;
  settings: NewsSettings;
  urls: Array<string>;
}

class BlogGrid extends Component<BlogGridProps> {
  state = {
    searchInputValue: '',
    data: [],
    dataToShow: [],
    toShow: 4,
  };

  componentDidMount() {
    this.onFilterArticles();
  }

  fetchMoreData = () => {
    setTimeout(() => {
      this.setState({ toShow: this.state.toShow + 4 }, () => {
        this.showArticles();
      });
    }, 1500);
  };

  showArticles = () => {
    const updatedArticles = [];

    this.state.data.forEach((article, index) => {
      if (index < this.state.toShow) {
        updatedArticles.push(article);
      }
    });

    this.setState({ dataToShow: updatedArticles });
  };

  onFilterArticles = () => {
    const { category, id } = this.props.pageContext;
    const { data } = this.props;
    let newData: Blog[] = [];
    let type = '';

    if (category) {
      type = 'categories';
    } else if (!category && id) {
      type = 'tags';
    }

    if (type === '') {
      newData = data;
    } else {
      data.forEach(article => {
        if (article[type].find(cat => cat === id)) {
          newData.push(article);
        }
      });
    }
    this.setState({ data: newData }, () => this.showArticles());
  };

  onSearchInputChange = e =>
    this.setState({ searchInputValue: e.target.value }, () => {
      const data: Blog[] = this.state.data;
      const newData: Blog[] = [];

      if (this.state.searchInputValue === '') {
        this.onFilterArticles();
      } else {
        data.forEach(article => {
          if (
            article.title &&
            article.title.toLocaleLowerCase().match(this.state.searchInputValue.toLocaleLowerCase())
          ) {
            newData.push(article);
          }
        });
        this.setState({ data: newData, toShow: 4 }, () => {
          this.showArticles();
        });
      }
    });

  getAllCategories = (category: string) => {
    const allCategories: Array<string> = flatten(
      this.props.data.map(article => article[category])
    ).sort();
    const uniqueCategories = [...new Set(allCategories)];

    return uniqueCategories;
  };

  render() {
    return (
      <BlogGridComponent id="blog">
        <BlogAsidePanel
          searchInputValue={this.state.searchInputValue}
          onSearchInputChange={this.onSearchInputChange}
          getAllCategories={this.getAllCategories}
          categoryId={this.props.pageContext.id || ''}
          settings={this.props.settings}
          langcode={this.props.pageContext.langcode}
          urls={this.props.urls}
        />
        <div>
          <BlogArticlesList
            articles={this.state.dataToShow}
            fetchMoreData={this.fetchMoreData}
            showLoader={this.state.toShow < this.state.data.length}
            settings={this.props.settings}
            langcode={this.props.pageContext.langcode}
            urls={this.props.urls}
          />
        </div>
      </BlogGridComponent>
    );
  }
}

export default BlogGrid;
