import React from 'react';
import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import BlogGrid from '@components/blog/BlogGrid';
import { NewsDataProps } from '@graphql/queries/NewsQuery';
import { PageContext } from '@content/types/pageContext';

export interface NewsProps extends NewsDataProps {
  pageContext: PageContext;
  urls: Array<string>;
}

export default (props: NewsProps) => {
  return (
    <Page>
      <Container>
        <BlogGrid
          data={props.allNews[props.pageContext.langcode]}
          pageContext={props.pageContext}
          settings={props.newsSettings[props.pageContext.langcode]}
          urls={props.urls}
        />
      </Container>
    </Page>
  );
};
