import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions, respondFrom, breakpoints, css } from '@styles';
import { Link } from '@utils';
import { BlogCategoryTitle } from '@components/blog/BlogCategoryTitle';
import * as utils from '@utils';
import { NewsSettings } from '@content/types/blog';

const BlogTagsComponent = styled.div`
  margin: 30px 0 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin: 30px 0;
    `
  )}
`;

const BlogTagsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  margin-top: -3px;

  li {
    margin-right: 15px;
  }

  a {
    display: inline-block;
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 300;
    padding: 3px 0;
    color: ${colors.green.medium};
  }
`;

interface BlogTagsProps {
  tags: Array<string>;
  settings: NewsSettings;
  langcode: string;
  urls: Array<string>;
}

export const BlogTags = ({ tags, settings, langcode, urls }: BlogTagsProps) => (
  <BlogTagsComponent>
    <BlogCategoryTitle title={settings.text4} />
    <BlogTagsList>
      {tags.map((tag, index) => (
        <li key={index}>
          <Link
            to={utils.langLink(
              langcode,
              `${urls[15]}/${encodeURI(utils.escapeDiacritics(tag.toLocaleLowerCase()))}`
            )}
          >
            {tag}
          </Link>
        </li>
      ))}
    </BlogTagsList>
  </BlogTagsComponent>
);
