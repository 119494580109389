import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions, respondFrom, css } from '@styles';

const InputWrapper = styled.div<InputStyleProps>`
  display: flex;

  input {
    border: 1px solid ${colors.gray};
    background-color: ${colors.white};
    font-size: ${dimensions.fontSize.regular}px;
    font-weight: 300;
    color: ${colors.text.default};
    outline: none;
    padding: 16px 14px 15px;

    padding-right: ${props => (props.buttonSpace ? props.buttonSpace : false)};

    &[disabled] {
      opacity: 0.5;
    }
  }

  label {
    color: ${colors.text.default};
    font-weight: 700;
    font-size: ${dimensions.fontSize.small}px;

    &.no-bold {
      font-weight: 300;
    }
  }

  &.horizontal {
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    ${respondFrom(
      1400,
      css`
        width: auto;
        margin-bottom: 0px;
      `
    )}

    input {
      width: 100%;

      ${respondFrom(
        1400,
        css`
          max-width: 180px;
        `
      )}
    }

    label {
      max-width: 100px;
      margin-right: 15px;

      ${respondFrom(
        1400,
        css`
          max-width: 75px;
        `
      )}
    }
  }

  &.vertical {
    flex-direction: column;
    margin-bottom: 10px;

    input {
      width: 100%;
    }

    label {
      margin-bottom: 10px;
    }
  }
`;

interface InputProps {
  typeInput: string;
  idInput: string;
  textLabel?: string;
  labelPosition: string;
  value?: string;
  action?: Function;
  placeholder?: string;
  buttonSpace?: string;
  disabled?: boolean;
  noBold?: boolean;
}

interface InputStyleProps {
  buttonSpace?: string;
}

export default ({
  typeInput,
  idInput,
  textLabel,
  labelPosition,
  placeholder,
  buttonSpace,
  value,
  action,
  disabled,
  noBold,
}: InputProps) => (
  <InputWrapper className={labelPosition} buttonSpace={buttonSpace}>
    {textLabel && textLabel !== '' ? (
      <label className={noBold ? 'no-bold' : ''} htmlFor={idInput}>
        {textLabel}
      </label>
    ) : null}
    <input
      id={idInput}
      type={typeInput}
      placeholder={placeholder}
      value={value}
      onChange={e => (action ? action(e) : null)}
      disabled={disabled}
    />
  </InputWrapper>
);
